:root {
    --background: #f5f3f2;
    --primary: #ffe900;
    --gray-main: #010c26;
    --gray-light: #676d7d;
}

@font-face {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 400;
    src: local(''),
            url('assets/fonts/proxima-nova-400.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: italic;
    font-weight: 400;
    src: local(''),
            url('assets/fonts/proxima-nova-400-italic.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 500;
    src: local(''),
            url('./assets/fonts/proxima-nova-500.woff2') format('woff2')
}

h1 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-size: 1.4em;
}

@font-face {
    font-family: proxima-nova;
    font-style: italic;
    font-weight: 500;
    src: local(''),
            url('assets/fonts/proxima-nova-500-italic.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 600;
    src: local(''),
            url('assets/fonts/proxima-nova-600.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: italic;
    font-weight: 600;
    src: local(''),
            url('assets/fonts/proxima-nova-600-italic.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 700;
    src: local(''),
            url('assets/fonts/proxima-nova-700.woff2') format('woff2')
}

@font-face {
    font-family: proxima-nova;
    font-style: italic;
    font-weight: 700;
    src: local(''),
            url('assets/fonts/proxima-nova-700-italic.woff2') format('woff2')
}

* {
    font-family: proxima-nova, sans-serif !important;
}

body {
    background-color: var( --background );
    color: var( --gray-main );
    line-height: 1.5rem;
}

a {
    color: var( --gray-main );
    text-underline-offset: 2px;
}

.main-logo {
    margin-right: 15px;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    overflow: hidden;
}

/* Header navigation */
nav a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    padding: 4px 4px 1px;
    margin-right: 20px;
    margin-left: 20px;
    color: rgb(1, 12, 38);
    text-transform: none;
    font-weight: bold;
}

nav a.active:after,
nav a:hover:after {
    bottom: -10px;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%,0);
    -moz-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    transform: translate(-50%,0);
    width: 44px;
}

nav a.user {
    font-size: 1rem;
    font-weight: 500;
}

.MuiDataGrid-main {
    background: white;
}

.MuiFormControl-root {
    width: 100%;
}

label[data-shrink=false] {
    font-size: small;
}

.MuiGrid-root .MuiTextField-root {
    width: 100%;
}

.MuiGrid-root .date-picker > div {
    display: flex;
    flex-direction: row;
}

/* Content Pages */
.content a {
    color: var( --primary );
}

.content a:focus,
.content a:hover {
    color: var( --gray-main );
}

.content table th,
.content table td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.content table th:first-of-type,
.content table td:first-of-type {
    padding-left: 0;
}

.content table th:last-of-type,
.content table td:last-of-type {
    padding-right: 0;
}

/* Footer */
.footer a:focus,
.footer a:hover {
    color: var( --gray-light );
}

.scan-image-wrapper {
    padding: 10px;
    background: white;
    box-sizing: border-box;
    resize: horizontal;
    border: 1px dashed;
    overflow: auto;
    max-width: 100%;
}
.scan-image-wrapper > canvas {
    width: 100%;
}
.scan-image-wrapper > div {
    background: yellow;
}
.scan-metadata {
    margin-top: 30px;
}
.scan-metadata-item {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}
.scan-metadata-item-label {
    font-weight: bold;
}
.scan-metadata-item > span {
    width: 40%;
}
.clearfix:after {
    display: block;
    content: "";
    clear: both;
}
.camera-feed button {
    margin-top: 50px;
}
.camera-feed__viewer {
    position: relative;
}
.camera-feed__viewer > video {
    /*
     This is set to cover because IOS Safari 14 - 16 handles the video feed
     differently on the second load. It switches from portrait to landscape.
     */
    object-fit: cover;
}
.camera-feed__viewer > canvas {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
